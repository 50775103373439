import VK from "@static/images/social/VK.svg";
import GooglePlus from "@static/images/social/GooglePlus.svg";
import LinkedIn from "@static/images/social/LinkedIn.svg";
import FaceBook from "@static/images/social/FaceBook.svg";
import { Box } from "@material-ui/core"
import React from "react"
import { makeComponentStyles } from "../mixins.js"
import IconButton from "@components/Buttons/IconButton.jsx"
import clsx from "clsx"
import { OPEN_PROVIDER } from "@api/modules/social.js"

const styles = () => ({
  social_auth__content: {
    margin: "10px 0",
    marginLeft: "-5px"
  },
  social_auth__item: {
    margin: "0 5px",
  },
  social_item_linkedin: {
    padding: "8px 12px 12px 12px !important"
  },
  social_auth__icon_google: {
    width: "20px"
  },
  social_auth__icon_linkedin: {
    width: "20px"
  },
})

function bindProvider(provider){
  return _ => OPEN_PROVIDER(provider);
}

function SocialAuth(){
  const {
    social_auth__content,
    social_auth__item,
    social_auth__icon_google,
    social_item_linkedin,
    social_auth__icon_linkedin
  } = makeComponentStyles(styles);

  return (
    <Box className={social_auth__content}>
      <IconButton className={social_auth__item} onClick={bindProvider("google")}>
        <img src={GooglePlus} alt="google-plus" className={social_auth__icon_google} />
      </IconButton>
      <IconButton className={social_auth__item} onClick={bindProvider("vk")}>
        <img src={VK} alt="vk" />
      </IconButton>
      {/*<IconButton className={clsx(social_auth__item, social_item_linkedin)}>*/}
      {/*  <img src={LinkedIn} alt="linked-in" className={social_auth__icon_linkedin} />*/}
      {/*</IconButton>*/}
      {/*<IconButton className={social_auth__item}>*/}
      {/*  <img src={FaceBook} alt="facebook" />*/}
      {/*</IconButton>*/}
    </Box>
  )
}

export default SocialAuth;
