import React, { useContext, useEffect, useState } from "react"
import Layout from "@components/Layout/Layout.jsx"
import { Box, Typography } from "@material-ui/core"
import { Form, FormControl, FormFeedback, FormGroup, Label } from "@components/Forms"
import { makeComponentStyles } from "@components/mixins.js"
import Button from "@components/Buttons/Button.jsx"
import Link from "@components/Buttons/Link.jsx"
import SocialAuth from "@components/Auth/SocialAuth.jsx"
import required from "@validator/rules/required";
import email from "@validator/rules/email";
import AuthContext from '@src/auth/context';
import { useIntl, navigate } from "gatsby-plugin-react-intl";

const styles = ({ breakpoints }) => ({
    form: {
        marginTop: "35px",
        width: "100%"
    },
    auth_button: {
        margin: "30px auto 15px auto",
        display: 'block'
    },
    footer__item: {
        margin: "10px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
})

const initBody = {
    name: "",
    surname: "",
    email: "",
    password: ""
}

const initErrors = {
    email: ""
}

function SignUp() {

    const {
        form,
        auth_button,
        footer__item
    } = makeComponentStyles(styles);

    const intl = useIntl();
    const [body, setBody] = useState(initBody);
    const [errors, setErrors] = useState(initErrors);
    const { auth } = useContext(AuthContext);

    const observeBody = (key, event) => {
        const newBody = {
            ...body,
            [key]: event.target.value.trim()
        }
        setBody(newBody);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors(initErrors);
        const { status, payload } = await auth.signup(body);
        if (!status) setErrors(errors => ({
            ...errors,
            ...payload.errors
        }));
        else navigate('/users/account')
    }

    return (
        <Layout type="auth">
            <Typography variant="h2" component="h1">{intl.formatMessage({ id: "signuplabel" })}</Typography>
            <Form className={form} onSubmit={handleSubmit} validate>
                <FormGroup name={'surname-input'}>
                    <Label>{intl.formatMessage({ id: "surname" })}</Label>
                    <FormControl onChange={observeBody.bind(null, "surname")} validations={[required]} />
                    <FormFeedback />
                </FormGroup>
                <FormGroup name={'name-input'}>
                    <Label>{intl.formatMessage({ id: "name" })}</Label>
                    <FormControl onChange={observeBody.bind(null, "name")} validations={[required]} />
                    <FormFeedback />
                </FormGroup>
                <FormGroup requestError={errors.email ? intl.formatMessage({ id: errors.email }) : false} name={'email-input'}>
                    <Label>Email</Label>
                    <FormControl type="email" onChange={observeBody.bind(null, "email")}
                        validations={[required, email]} />
                    <FormFeedback />
                </FormGroup>
                <FormGroup name={'password-input'}>
                    <Label>{intl.formatMessage({ id: "password" })}</Label>
                    <FormControl type="password" onChange={observeBody.bind(null, "password")}
                        validations={[required]} />
                    <FormFeedback />
                </FormGroup>
                <Button type="submit" className={auth_button}>{intl.formatMessage({ id: "signup" })}</Button>
            </Form>
            <Box className={footer__item}>
                <Typography> {intl.formatMessage({ id: "or" })} </Typography>
            </Box>
            <Box className={footer__item}>
                <Typography>
                    {intl.formatMessage({ id: "signupwith" })}
                </Typography>
                <SocialAuth />
            </Box>
            <Box className={footer__item}>
                <Typography>
                    {intl.formatMessage({ id: "account" })} <Link to="/users/login">{intl.formatMessage({ id: "login" })}</Link>
                </Typography>
            </Box>
        </Layout>
    )
}

export default SignUp;
