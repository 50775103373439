import { baseURL } from "../axios.js"

const makeURL = (path) => `${baseURL}${path}`;

export const providers = new Map()
  .set('google', makeURL('/user/login/socials/google'))
  .set('vk', makeURL('/user/login/socials/vk'));

export function OPEN_PROVIDER(provider){
  window.open(
    providers.get(provider),
    "_self"
  );
}
